export const COLOUR = {
	primary: '#fc8c00',
	secondary: '#83b735',
	orange: '#fc8c00',
	green: '#83b735',
	charcoal: '#333333',
	mineShaft: '#222222',
	grey: 'rgba(0,0,0,.54)',
	lightGrey: '#f9f9f9',
	lightBorder: '#e8e8e8',
	regularBorder: '#cacaca',
	boldBorder: '#9f9f9f',
	success: '#4caf50',
	error: '#f44336',
	warning: '#ff9800',
}

export const FONTS = {
	primary: {
		family: 'Roboto, sans-serif',
		weight: {
			light: 300,
			regular: 400,
			semiBold: 500,
			bold: 700,
			extraBold: 900,
		},
	},
	secondary: {
		family: 'Open Sans, sans-serif',
		weight: {
			light: 300,
			regular: 400,
			semiBold: 600,
			bold: 700,
			extraBold: 800,
		},
	},
	sizes: {
		extraSmall: '0.7rem',
		small: '0.875rem',
		regular: '1rem',
		midMedium: '1.2rem',
		medium: '1.5rem',
		midLarge: '1.7rem',
		large: '2rem',
		xLarge: '2.5rem',
		xxLarge: '3.2rem',
	},
}

export const GUTTER = {
	extraSmall: '25px',
	small: '50px',
	medium: '75px',
	large: '96px',
}

export const BREAK_POINTS = {
	smDown: '575.98px',
	smUp: '576px',
	mdDown: '767.98px',
	mdUp: '768px',
	lgDown: '991.98px',
	lgUp: '992px',
	lgTabletUp: '1024px',
	lgTabletDown: '1023.98px',
	xlDown: '1199.98px',
	xlUp: '1200px',
}

export const MEDIA_QUERIES = {
	smDown: `@media all and (max-width: ${BREAK_POINTS.smDown})`,
	smUp: `@media all and (min-width: ${BREAK_POINTS.smUp})`,
	mdDown: `@media all and (max-width: ${BREAK_POINTS.mdDown})`,
	mdUp: `@media all and (min-width: ${BREAK_POINTS.mdUp})`,
	lgDown: `@media all and (max-width: ${BREAK_POINTS.lgDown})`,
	lgUp: `@media all and (min-width: ${BREAK_POINTS.lgUp})`,
	lgTabletDown: `@media all and (max-width: ${BREAK_POINTS.lgTabletDown})`,
	lgTabletUp: `@media all and (min-width: ${BREAK_POINTS.lgTabletUp})`,
	xlDown: `@media all and (max-width: ${BREAK_POINTS.xlDown})`,
	xlUp: `@media all and (min-width: ${BREAK_POINTS.xlUp})`,
	between: {
		sm_lg: `@media all and (min-width: ${BREAK_POINTS.smUp}) and (max-width: ${BREAK_POINTS.lgDown})`,
	},
}

export const Z_INDEX = {
	layer1: 50,
	layer2: 100,
	popup: 1050,
}

export const SIZE = {
	icon: {
		small: '16px',
		medium: '25px',
		large: '32px',
	},
}
