import React, { useState, useEffect } from 'react'
import styled from '@emotion/styled'
import { COLOUR, FONTS, MEDIA_QUERIES } from './theme'

// style
const PageBackground = styled.div`
	background: black;
	width: 100%;
	height: 100%;
	display: flex;
	place-items: center;
	position: absolute;
`

const Wrapper = styled.div`
	max-width: 800px;
	margin: 0 auto;
	color: white;
`

const InnerWrapper = styled.div`
	border: 2px solid rgba(255, 255, 255, 0.15);
	padding: 2.5em 2em;
	background-color: ${COLOUR.mineShaft};
`

const InputsWrapper = styled.div`
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	grid-gap: 16px;

	${MEDIA_QUERIES.mdDown} {
		grid-template-columns: 1fr;
	}
`

const InputBox = styled.div``

const StyledLabel = styled.label`
	font-size: ${FONTS.sizes.small};
	font-weight: ${FONTS.secondary.weight.semiBold};
	margin-bottom: 8px;
	display: block;
`

const StyledInput = styled.input`
	text-align: left;
	padding: 0 12px;
	background: rgba(255, 255, 255, 0);
	border: 1px solid rgba(255, 255, 255, 0.15);
	color: rgba(255, 255, 255, 0.6);
	vertical-align: middle;
	max-width: 100%;
	width: 100%;
	height: 42px;
	font-size: ${FONTS.sizes.small};
	font-weight: ${FONTS.secondary.weight.semiBold};
	transition: all 0.25s ease-in-out;
	box-sizing: border-box;

	&:focus {
		border-bottom-color: ${COLOUR.primary};
	}
`

const AdviceText = styled.p`
	margin: 1rem 0 0;
`

const ResultFigure = styled.span`
	display: inline-block;
	min-width: 15px;
	text-align: center;
	height: auto;
	border: 0;
	border-bottom: 1px solid ${COLOUR.primary};
	font-weight: ${FONTS.secondary.weight.semiBold};
	color: ${COLOUR.primary};
	line-height: 1.2;
`

const App = () => {
	const [targetStrength, setTargetStrength] = useState('')
	const [baseStrength, setBaseStrength] = useState('')
	const [bottleSize, setBottleSize] = useState('')
	const [adviceResult, setAdviceResult] = useState('')

	useEffect(() => {
		if (targetStrength > 0 && baseStrength > 0 && bottleSize > 0) {
			const result = ((targetStrength / baseStrength) * bottleSize).toFixed(1)
			setAdviceResult(result)
		} else {
			setAdviceResult('')
		}
	}, [targetStrength, baseStrength, bottleSize])

	return (
		<PageBackground>
			<Wrapper>
				<h1>E-liquid Calculator</h1>
				<InnerWrapper>
					<InputsWrapper>
						<InputBox>
							<StyledLabel>Target Strength (mg/ml)</StyledLabel>
							<StyledInput
								type="number"
								value={targetStrength}
								onChange={(e) => setTargetStrength(e.target.value)}
							/>
						</InputBox>

						<InputBox>
							<StyledLabel>Base Strength (mg/ml)</StyledLabel>
							<StyledInput
								type="number"
								value={baseStrength}
								onChange={(e) => setBaseStrength(e.target.value)}
							/>
						</InputBox>

						<InputBox>
							<StyledLabel>Bottle Size (ml)</StyledLabel>
							<StyledInput
								type="number"
								value={bottleSize}
								onChange={(e) => setBottleSize(e.target.value)}
							/>
						</InputBox>
					</InputsWrapper>
					<AdviceText>
						Add <ResultFigure>{adviceResult}</ResultFigure> milliliters to your bottle. Also remember to
						remove the same amount you are adding from your nicotine free e-liquid bottle before you add the
						nicotine base so that the total end volume of your bottle is as you stated above (or so your
						bottle is full after adding the base).
					</AdviceText>
				</InnerWrapper>
			</Wrapper>
		</PageBackground>
	)
}

export default App
